import { render, staticRenderFns } from "./TopBar.vue?vue&type=template&id=405448c5&scoped=true"
import script from "./TopBar.vue?vue&type=script&lang=js"
export * from "./TopBar.vue?vue&type=script&lang=js"
import style0 from "./TopBar.vue?vue&type=style&index=0&id=405448c5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "405448c5",
  null
  
)

export default component.exports