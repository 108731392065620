<template>
  <div class="w-100">

    <div data-v-1fa066c4="" class="w-100">

      <div class="header" data-v-1fa066c4="">
        <h1 class="logo" data-v-1fa066c4=""><a data-v-1fa066c4="" @click="$move('/')">
        <img src="@/assets/img/tpy/logo.png" data-v-1fa066c4=""></a></h1>

        <div class="mr-2 logininfowrap" data-v-1fa066c4="" v-if="isLogin">
          <ul class="mym" data-v-1fa066c4="" >
            <v-btn
              icon
              color="primary"
              v-if="(role ==='ROLE_AGENCY' && rateFlag !== 'false')"
              @click="$move('/partner/member')"
            >
              <v-icon>
                mdi-alpha-p-box
              </v-icon>
            </v-btn>

            <li class="myc mx-1" data-v-1fa066c4=""><img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABHNCSVQICAgIfAhkiAAAAnNJREFUOE+NVM9rU0EQ/maTSCuJfTlJ8UdTaUtFwQR70EubgiCIkCqIF5EE9CrFUxWh7Z/gpYgemnoSemhAqCJoX/Qkiol4qFp/NFRIsWJeQktCmuw676WveSatZi+7O7Pz7bcz3yxhh1FcjIYl5BCRCEupgiQISqk0gXQJJH1H43pjGDkNuVRUc7vL02wbIYdHKaBhn6hU9sT8obhhx28fz6UuBV2EOUAFOIz9HP2PWUEuu5S44A3Npk0wCyiXGtFIqhTHBsybTQa7jb/8hGUlKOQPJYwa0OtzCc5BhPgUz/yM1me+PO4/NR+j3KszYSmw0MjAow2g/dAVuL19zNsDVd1A+dcCNr7ebSIrJYZpLTk4wQzGG5loAw/haj+MyvoSVKUAcu/DpvEWG9/uNTEGxCT9fHHaLOWQ85q2zvPw9d9mkM/IvYnunrC6J0mrz04azKjDycjXewN7A1dRWn2K/Ic7reTMoOyTEwaDdDh14u0bhfdIDKXsPIz3t6wqNuvKlohFK08/HvfrrFh+mq0bwNdzjZ92E5uFRay9vNigpxqo+QJbZxyfpJW5ngk2j9v6sEvfeTbJyT6A8u93XLESyNWGYvY51r/c30EimKSV2YNhRWK7/Hau2vYPQjs+Bo92zOKuNgsofJpC/uOUlTN7mOcFXMOWJfOoM8FEI/9ri538jDnTdXk1agF9n9Y05RFpvqnLTmyLcwblarA7ZtRaZAssKIVM8LqrLo96AWo2x56QEVUxwiD1pnWAaZVqMc7/T6TGyO65WiM79jMuKo12x9D8jTjlu/TAzR+aDHNgmO3BLSZpTqwuhNB7r1d053lz/QcQ80OKHpX9FAAAAABJRU5ErkJggg=="
                data-v-1fa066c4=""><span data-v-1fa066c4="">{{ balance.money | comma }}</span>
            </li>
            <li class="myp" data-v-1fa066c4="" @click="$move('/point')"><img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABHNCSVQICAgIfAhkiAAAAn5JREFUOE+NVE1IVFEU/s4LgiDSJrRoa4QM2jwz7Y+c96CkRUULF1ELdaEFA+WASEGMYZBUi6mFJRk0U2IGCVYUJMg8jSImxDdq5qpFP6C4aKIWbWZu592Z9+b1xtAzMPeec8/9zjnfuecRVpCB2XOaQqKZADUroBJvBIRJUEzW423V/Yb3GrsUpG+6TSVko2zRIG+LwuG/uiGghEM1A6bt4ABFky1W5ARHLiWQlQH/u1YGJXZw2dMQpIfrYxJMAt14e1oVpCR4WyotViJrW9MksnrXwSEum6Vn8pRVc3Btd4tiGJGGYZ0uJ5o0kUXCosAWi5rWmggqNlc7tvSfZSS/j+HNl1FJndufs9ep8/XJGNfenCM2nxN7tddewQ7fLswvJyWYv6wePxisd7I9h+LyZy7jdP7lcYusgLes0N6r2LklgPHPI3i+EEe3fh++DeXoeHWiiEJGNens6FFhdUeKDJTrzoX911BZpiK1+B4b129Chc+Ppd/f0D3eJs9tNOlv/VqfHnE9lgJPnYeuw19e4xgWf33FUOoO5pam3E/P2dOZx7rJIQJ2JvZ6UbuJqq278eLTMJ7MDDiZev3yeoqaHjXEOM9mb1Mjh6Oo3laLZx+HMDjdv8rDQpyOPdinKVASdkvtiD2NtxHYvgcjs4N4OHXXaflKGQlap0uWG+/VG7wJrlj8KkYmeGKsPalJIK2vTgUyBnNVYoWWM1VI8b86+/0UIqMZITM3IpYciKo8b8JgjJKigfUOsNV7BqEMae/CZmFobbC6qF/NZHGLX0XQJsXmxK0rhAnKoOND13zxZ8RNRVVvpUYKWhiES0Ygf5YiIUz+DsXmLi0YXur+AjqlMVLqV6vTAAAAAElFTkSuQmCC"
                data-v-1fa066c4=""><span data-v-1fa066c4="">{{ parseInt(balance.mileage) | comma }}</span></li>
          </ul>
          <a class="mymsg" data-v-1fa066c4="" v-if="isLogin" @click="$move('/message')">
            <img src="/img/message_off.7eef911f.svg" data-v-1fa066c4=""><span
              class="mgsn" data-v-1fa066c4="">{{ unreadMessage}}</span></a>
        </div><!---->

        <div data-v-1fa066c4="" v-if="!isLogin">
          <a class="mloginbtn" data-v-1fa066c4="" @click="openJoinDialog">JOIN</a>
          <a class="mloginbtn ml10" data-v-1fa066c4="" @click="openLoginDialog">LOGIN</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import Proxyable from 'vuetify/lib/mixins/proxyable'
// Vuex

export default {
  name: 'DashboardCoreSettings',
  data: () => ({
    menu: false,
    drawer:false,
    calcCartHeight: 0,
    menuItems: [
      {title: '라이브카지노', path: '/casino/live'},
      {title: '호텔카지노', path: '/casino/hotel'},
      {title: '슬롯', path: '/casino/slot'},
      {title: '베팅내역', path: '/mybet/SPORTS_GAME'},
      {title: '공지사항', path: '/notice/NOTICE'},
      {title: '이벤트', path: '/notice/EVENT'},
      {title: '고객센터', path: '/customer'},
      {title: '충전', path: '/deposit'},
      {title: '환전', path: '/withdrawal'},
      {title: '포인트', path: '/point'},
    ],
  }),
  props: {

    balance: {
      type: Object
    },
    unreadMessage: {
      type: Number
    },
    nickname: {
      type: String
    },
    gradeValue: {
      type: String
    },
    isLogin: {
      type: Boolean
    },
    role: {
      type: String
    },
    rateFlag: {
      type: String
    },
    hasChildren: {
      type: String
    },
  },

  methods: {
    logout: function () {

      this.$emit('logout');
    },
    openStatusBoard: async function () {
      await this.$nextTick();
      console.log('window', window.innerHeight)
      setTimeout(() => {
        console.log(this.$refs.statusBoard)
        console.log(this.$refs.statusBoard.$el.clientHeight)
        console.log(this.$refs.statusBoard.$el.scrollHeight)
        console.log(window.innerHeight)
      }, 400)

    },
    openRecommenderDialog: function () {
      this.$emit('openRecommenderDialog')
    },
    openLoginDialog: function () {
      this.$emit('openLoginDialog')
    },

    openJoinDialog: function () {
      this.$emit('openJoinDialog')
    },
    goLink: function (target) {
      this.$emit('goLink', target);
    },

  },
  mounted() {
  },
  computed: {},
}
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 3px
    border-style: solid
    border-color: transparent !important

    &--active
      border-color: #00cae3 !important
</style>
