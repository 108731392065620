<template>
  <v-dialog
      :value="dialog"
      @input="toggleDialog"
      max-width="802px"
      :fullscreen="!$vuetify.breakpoint.lgAndUp"
  >
    <div
        class=" fill-height w-100"
        ref="containerWrap"
    >

      <v-card
          class="mx-auto fill-height"
          color="#141414"
      >
        <v-card-text
            v-if="$vuetify.breakpoint.lgAndUp"
            class="pa-9 pt-0 joinWrap"
        >
          <div>
            <div class="d-flex justify-center mt-5">
              <div class="">
                <v-img class="bg1" src="@/assets/img/tpy/logo.png" :style="{width:'163px'}"></v-img>
              </div>
              <div>
                <v-btn
                    icon
                    x-large
                    class="closeBtn"
                    color="#ffffff"
                    @click="toggleDialog"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
            </div>
<!--            <div>
              <h2 class="loginWelcomeText">
                택시에 오신것을 환영합니다!
              </h2>

            </div>-->

          </div>
          <validation-observer
              ref="boardWriteForm"
              v-slot="{ invalid }"
          >
            <form
                class=""
                @submit.prevent="login"
            >
              <v-row
                  class="ma-0"
                  align="center"
                  justify="center"
                  no-gutters
              >
                <v-col
                    cols="8"
                    sm="8"
                    md="8"
                    class="pa-0 mb-3 mt-10"
                >
                  <validation-provider
                      v-slot="{ errors }"
                      name="userId"
                      rules="required"
                  >
                    <v-text-field
                        v-model="userId"
                        placeholder="아이디"
                        required
                        solo
                        name="userId"
                        class="searchInput"
                        background-color="#333"
                        :error-messages="errors"
                        hide-details
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                    cols="8"
                    sm="8"
                    md="8"
                    class="pa-0 my-2"
                >
                  <validation-provider
                      v-slot="{ errors }"
                      name="userPassword"
                      rules="required"
                  >
                    <v-text-field
                        v-model="userPassword"
                        placeholder="비밀번호"
                        solo
                        required
                        type="password"
                        name="userPassword"
                        class="searchInput"
                        background-color="#333"
                        :error-messages="errors"
                        hide-details
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row
                  class="ma-0"
                  align="center"
                  justify="center"
                  no-gutters
              >
                <v-col
                    cols="4"
                    sm="4"
                    md="4"
                    class="pa-0 my-2 pr-1"
                >
                  <div class="np-captcha-container">
                    <div class="d-flex justify-space-between np-captcha px-3"
                         v-if="captcha && captcha.length"
                    >
                      <div>
                        <div
                            v-for="(c, i) in captcha"
                            :key="i"
                            :style="{
                            fontSize: getFontSize() + 'px',
                            fontWeight: 800,
                            transform: 'rotate(' + getRotationAngle() + 'deg)',
                          }"
                            class="np-captcha-character"
                        >
                          {{ c }}
                        </div>
                      </div>

                      <v-btn
                          icon
                          large
                          class="align-center"
                          @click="createCaptcha"
                      >
                        <v-icon>
                          mdi-refresh
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>

                </v-col>
                <v-col
                    cols="4"
                    sm="4"
                    md="4"
                    class="pa-0 my-2 pl-1"
                >
                  <validation-provider
                      v-slot="{ errors }"
                      name="recaptcha"
                      rules="required"
                  >
                    <v-text-field
                        v-model="recaptcha"
                        placeholder="자동입력방지코드"
                        solo
                        required
                        name="recaptcha"
                        class="recaptcha rounded-0"
                        background-color="#333"
                        :error-messages="errors"
                        hide-details
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row
                  class="ma-0"
                  align="center"
                  justify="center"
                  no-gutters
              >
                <v-col
                    cols="8"
                    sm="8"
                    md="8"
                    class="pa-0 my-2"
                >
                  <v-btn
                      class="memeberSubmitBtn"
                      block
                      text
                      outlined
                      x-large
                      type="submit"
                      :disabled="invalid"
                      :loading="loginBtnLoading"
                  >
                    로그인
                  </v-btn>
                </v-col>
              </v-row>
              <div class="text-center mb-4">
                <div>
                  <h2 class="joinTxt">
                    아이디가 없으신가요?
                    <span
                        @click="openJoinDialog"
                        class="joinBtn"
                    >
                      회원가입
                    </span>
                  </h2>
                </div>
              </div>
            </form>
          </validation-observer>
        </v-card-text>
        <v-card-text
            v-else
        >
          <div class="d-flex justify-center my-12">
            <div class="">
              <v-img class="bg1" src="@/assets/img/tpy/logo.png" :style="{width:'108px'}"></v-img>
            </div>
            <div>
              <v-btn
                  icon
                  x-large
                  class="closeBtn"
                  color="#ffffff"
                  @click="toggleDialog"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </div>


          <validation-observer
              ref="boardWriteForm"
              v-slot="{ invalid }"
          >
            <form
                class=""
                @submit.prevent="login"
            >
              <v-row
                  class="ma-0"
                  align="center"
                  justify="center"
                  no-gutters
              >
                <v-col
                    cols="10"
                    sm="10"
                    md="10"
                    class="pa-0 mb-3 mt-10"
                >
                  <validation-provider
                      v-slot="{ errors }"
                      name="userId"
                      rules="required"
                  >
                    <v-text-field
                        v-model="userId"
                        placeholder="아이디"
                        required
                        solo
                        outlined
                        name="userId"
                        class="searchInput border-32"
                        background-color="#141414"
                        :error-messages="errors"
                        hide-details
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                    cols="10"
                    sm="10"
                    md="10"
                    class="pa-0 my-2"
                >
                  <validation-provider
                      v-slot="{ errors }"
                      name="userPassword"
                      rules="required"
                  >
                    <v-text-field
                        v-model="userPassword"
                        placeholder="비밀번호"
                        solo
                        required
                        outlined
                        type="password"
                        name="userPassword"
                        class="searchInput border-32"
                        background-color="#141414"
                        :error-messages="errors"
                        hide-details
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                    cols="10"
                    sm="10"
                    md="10"
                    class="pa-0 my-2"
                >
                  <div class="np-captcha-container">
                    <div class="d-flex justify-space-between np-captcha px-3"
                         v-if="captcha && captcha.length"
                    >
                      <div>
                        <div
                            v-for="(c, i) in captcha"
                            :key="i"
                            :style="{
                            fontSize: getFontSize() + 'px',
                            fontWeight: 800,
                            transform: 'rotate(' + getRotationAngle() + 'deg)',
                          }"
                            class="np-captcha-character"
                        >
                          {{ c }}
                        </div>
                      </div>

                      <v-btn
                          icon
                          large
                          class="align-center"
                          @click="createCaptcha"
                      >
                        <v-icon>
                          mdi-refresh
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col
                    cols="10"
                    sm="10"
                    md="10"
                    class="pa-0 my-2"
                >
                  <validation-provider
                      v-slot="{ errors }"
                      name="recaptcha"
                      rules="required"
                  >
                    <v-text-field
                        v-model="recaptcha"
                        placeholder="자동입력방지코드"
                        solo
                        required
                        name="recaptcha"
                        class="recaptcha"
                        background-color="#333"
                        :error-messages="errors"
                        hide-details
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                    cols="10"
                    sm="10"
                    md="10"
                    class="pa-0 my-2"
                >
                  <v-btn
                      class="memeberLoginBtnMobile border-32"
                      block
                      text
                      outlined
                      x-large
                      type="submit"
                      :disabled="invalid"
                      :loading="loginBtnLoading"
                  >
                    로그인
                  </v-btn>
                </v-col>
              </v-row>
              <div class="text-center mb-4">
                <div>
                  <h2 class="joinTextMobileInLogin">
                    아이디가 없으신가요?
                    <span
                        @click="openJoinDialog"
                        class="joinBtn"
                    >
                      회원가입
                    </span>
                  </h2>
                </div>
              </div>

            </form>


          </validation-observer>
        </v-card-text>
      </v-card>
    </div>
    <v-overlay
        absolute
        z-index="999"
        :value="loading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>

</template>


<script>


// import axios from "axios";
import {eventBus} from "@/plugins/eventBus";

export default {
  name: "MemberLogin",
  data: () => ({
    loginBtnLoading: false,
    loading: false,
    userId: '',
    userPassword: '',
    passwordShow: false,
    dialog: {
      recommender: false,
      alert: false,
      alertText: '',
      alertActionType: '',
      alertOption: {
        persistent: false
      },
      underMaintenance: false,
      underMaintenanceText: '',
      snackBar: false,
      snackBarText: '',
      join: false,
      joinData: {}
    },
    captchaLength: 5,
    captcha: [],
    fullCaptcha: '',
    recaptcha: '',
  }),
  components: {},
  props: {
    loginDialog: {
      type: Boolean
    },
  },

  methods: {
    changeLoginBtnLoading: function (flag) {
      this.loginBtnLoading = flag
    },
    toggleDialog: function () {
      // this.$emit('toggleDialog');
      this.$emit('update:loginDialog', !this.loginDialog);
      // this.$emit('input');
    },

    login: function () {
      if (this.fullCaptcha !== this.recaptcha) {
        eventBus.showAlert('자동입력 방지 코드를 다시 입력해주세요', this.dialog.alertOption, this.dialog.alertActionType)
      } else {
        this.$emit('login', this.userId, this.userPassword);
      }

    },
    openJoinDialog: function () {
      this.$emit('openJoinDialog',);
    },


    createCaptcha() {
      let tempCaptcha = "";
      for (let i = 0; i < this.captchaLength; i++) {
        tempCaptcha += this.getRandomCharacter();
      }
      this.captcha = tempCaptcha.split("");
      this.fullCaptcha = tempCaptcha
      console.log(this.captcha)
      console.log(this.fullCaptcha)
    },
    getRandomCharacter() {
      // const symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      // const randomNumber = Math.floor(Math.random() * 36);
      const symbols = "0123456789";
      const randomNumber = Math.floor(Math.random() * 10);
      return symbols[randomNumber];
    },
    getFontSize() {
      const fontVariations = [14, 16, 20, 30, 36];
      return fontVariations[Math.floor(Math.random() * 5)];
    },
    getRotationAngle() {
      const rotationVariations = [5, 10, 20, 25, -5, -10, -20, -25];
      return rotationVariations[Math.floor(Math.random() * 8)];
    },


  },
  // computed: {
  //   getFontSize() {
  //     const fontVariations = [14, 20, 30, 36, 40];
  //     return {
  //       fontSize: fontVariations[Math.floor(Math.random() * 5)] + 'px',
  //     }
  //   },
  //   getRotationAngle() {
  //     const rotationVariations = [5, 10, 20, 25, -5, -10, -20, -25];
  //     return {
  //       transform: 'rotate(' + rotationVariations[Math.floor(Math.random() * 8)] + 'deg)',
  //     }
  //   },
  // },
  mounted() {
    this.createCaptcha();
  },
  watch: {
    dialog: function () {
      this.createCaptcha();
    },
  }


}
</script>

<style scoped>
.mainLogo {
  padding: 80px 0;
}

.joinBtn {
  color: #e5972d;
  text-decoration: underline;
  cursor: pointer;
}

div ::v-deep .searchInput .v-input__slot input {
  text-align: center;
  font-size: 25px;
}

div ::v-deep .searchInput .v-text-field--solo .v-input__control {
  min-height: 72px;
}

div ::v-deep .recaptcha.v-text-field.v-text-field--solo .v-input__control {
  min-height: 44px !important;
  height: 44px !important;
}

button:disabled {
  opacity: 1 !important;
}

.np-captcha-container {
  background: #eee;

}

.np-captcha {
  font-size: 50px;
  background-color: #333;
}

.np-button {
  padding: 6px 10px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  font-size: 16px;
}

.np-captcha-character {
  display: inline-block;
  letter-spacing: 14px;
}
</style>
